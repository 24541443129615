import React, { useContext } from "react";
import { AppContext } from "../App";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

export default function CollectionItemPhotos({ d }) {
    const strapiURL = useContext(AppContext)

    if (!d  || !d.Photos || !Array.isArray(d.Photos)) {
      return null; 
    }

    return (
      <ImageGallery
        items={d.Photos.map(photo => ({
          original: `${strapiURL}${photo?.formats?.large?.url || photo?.formats?.medium?.url || ''}`,
          thumbnail: `${strapiURL}${photo?.formats?.thumbnail?.url || ''}`,
          originalAlt: photo?.alternativeText,
          thumbnailAlt: photo?.alternativeText,
        }))}
        showFullscreenButton={true}
        showPlayButton={true}
        autoPlay={true}
        slideInterval={6000}
        slideDuration={450}
        showBullets={true}
        showThumbnails={true}
        showIndex={true}
        infinite={true}
        showNav={true}
        thumbnailPosition="top"
        useBrowserFullscreen={true}
        useWindowKeyDown={true}
      />
    );
}
