import React, { useEffect, useState, useContext  } from "react";
import { Link } from 'react-router-dom'
import axios from 'axios'
import HomepageBlog from '../components/HomepageBlog'
import HomepagePhoto from '../components/HomepagePhoto'
import { AppContext } from "../App";

export default function Homepage() {
  const strapiURL = useContext(AppContext)
  const token = process.env.REACT_APP_API_KEY
  const headers = {
      headers: { Authorization: `Bearer ${token}` }
  };

  const[data,setData] = useState([])
  const [page, setPage] = useState(1); // Numer aktualnej strony
  const pageSize = 5; // Liczba wyników na stronę
  const [totalPages, setTotalPages] = useState(0);

  const[dataNewPhoto,setDataNewPhoto] = useState([])

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const response = await axios.get(`${strapiURL}/api/collections`, { ...headers,
          params: {
            'sort[0]': `createdAt:DESC`, // Sortowanie malejąco po dacie
            'pagination[page]': page,
            'pagination[pageSize]': 5,
            'populate': '*', 
          }
        });

        // console.log(response.data.data)
        setDataNewPhoto(response.data.data);
        
      } catch (error1) {
        console.error(error1);
      }
    };
  
      fetchData1();
    }, [page]);



  useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${strapiURL}/api/blogs`, { ...headers,
        params: {
          'sort[0]': 'Date:DESC', // Sortowanie malejąco po dacie
          'pagination[page]': page,
          'pagination[pageSize]': pageSize,
           populate: '*', 
        }
      });
      // console.log(response.data)
      setTotalPages(response.data.meta.pagination.pageCount);
      setData(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

    fetchData();
  }, [page]);
  
  const handleNextPage = () => {
    // Przejdź do następnej strony
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    // Przejdź do poprzedniej strony
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const pageButtons = [];
  for (let i = 1; i <= totalPages; i++) {
    pageButtons.push(
      <li key={i} className={`page-item ${i === page ? "active" : ""}`}>
        <button className="page-link" onClick={() => setPage(i)}>
          {i}
        </button>
      </li>
    );
  }

  return (
    <section className="py-5 news-page">
      
      <div className="container">
        <div className="row">

          <div className="col-lg-8">
            <h4 className="font-weight-semi-bold">Aktualności</h4>
            <div className="list-group">
              {data.map((d,i) => {
                  return  <HomepageBlog key={d.id} d={d} />
                })
              }
            </div>
            <div className="card-body">
              <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
                      <button className="page-link" onClick={handlePreviousPage}>Poprzednia</button>
                    </li>
                    {pageButtons}
                    <li className={`page-item ${page === totalPages ? "disabled" : ""}`}>
                      <button className="page-link" onClick={handleNextPage}>Następna</button>
                    </li>
                  </ul>
                </nav>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="col-lg-12">
            <iframe
              src="https://www.facebook.com/plugins/likebox.php?href=http%3A%2F%2Fwww.facebook.com%2Fpages%2FStareKomputery%2F156767034384385"
              scrolling="no"
              frameborder="0"
              style={{ border: 'none', overflow: 'hidden', width: '300px', height: '162px' }}
              allowtransparency="true">
            </iframe>
  </div>

            <h4 className="font-weight-semi-bold">Ostanie dodane</h4>
            {dataNewPhoto.map((d,i) => {
                return  <HomepagePhoto key={d.id} d={d} />
              })
            }
          </div>
        </div>
      </div>
    </section>
  );
}
