import React from "react";

export default function CollectionItemLinks({ linksArray = [] }) {
    // console.log(linksArray)

    if (!linksArray || !linksArray.data ) {
        // console.log('errror')
        return null; 
    }

    return (
        // <div className="list-group ">
          <ul className="list-group">
            {linksArray.data.map((item, index) => (
              <li className="list-group-item" key={index}>
                <div className="row">
                    <div className="col-lg-4">
                        {item?.Name}
                    </div>
                    <div className="col-lg-4">
                        {item?.Description}
                    </div>
                    <div className="col-lg-4">
                        <a href={`https://${item?.Link}`} target="_blank" rel="noopener noreferrer">
                        {item?.Link}
                        </a>
                    </div>
                </div>
              </li>
            ))}
          </ul>
        // </div>
      );
}
