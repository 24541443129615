import React, { useContext } from "react";
import { AppContext } from "../App";

export default function HomepagePhoto({ d }) {
  
  const strapiURL = useContext(AppContext);
  
  let imageUrl;
  if (d?.Photos?.length > 0) {
      // Losujemy pozycję z dostępnych obiektów
      const randomIndex = Math.floor(Math.random() * d.Photos.length);
      const photo = d.Photos[randomIndex];

      // Sprawdzamy czy wylosowany obiekt posiada potrzebne klucze
      if (photo?.formats?.small?.url) {
          imageUrl = `${strapiURL}${photo.formats.small.url}`;
      }
  }

  return (
    <div className="col-lg-12">
      <div className="osahan-card bg-dark1">
        <a href={`/collection/${d.documentId}`}>
          <img className="img-fluid" src={imageUrl} alt={d.Name} />
        </a>
      </div>
    </div>
  );
}



