import React, { useEffect, useState, useContext, useMemo} from 'react'
import { AppContext } from "../App";
import { useParams } from 'react-router-dom'
import axios from 'axios'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import BlogListsBlog from '../components/BlogListsBlog';
import ReactMarkdown from 'react-markdown';

export default function CollectionItem() {
  const strapiURL = useContext(AppContext)
  const token = process.env.REACT_APP_API_KEY
  const headers = useMemo(() => ({
      headers: { Authorization: `Bearer ${token}` }
  }), [token]);

  const {id} = useParams();
  const [item, setItem] = useState(null);
  const [itemBlogsList, setItemBlogsList] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${strapiURL}/api/blogs/${id}`, { ...headers,
          params: {
            populate: '*', 
          }
        });
        setItem(response.data.data);
        try {
          const responseBlogs = await axios.get(`${strapiURL}/api/blogs`, { ...headers,
            params: {
              'sort[0]': `createdAt:DESC`, // Sortowanie malejąco po dacie
              'pagination[page]': 1,
              'pagination[pageSize]': 5,
              'populate': '*', 
            }
          });
          setItemBlogsList(responseBlogs.data.data);
          
        } catch (error1) {
          console.error(error1);
        }

      } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
      }
    };
      fetchData();
    }, [headers, id, strapiURL]);

  
  return (
    <section className="py-5 news-page">
      <div className="container">   
        <div className="d-flex align-item-center title mb-3">
            <h5 className="m-0 font-weight-normal">Blog
            </h5>
            <div className="ml-auto d-flex align-items-center">
              <a className="mr-2 text-white-50" href="/"><i className="feather-chevron-left"></i> do głównej</a>
            </div>
        </div>
        <div className="row">

          <div className="col-lg-8">


            {
              item?.Photos?.length > 0 && (
                <ImageGallery
                  items={item.Photos.map(photo => ({
                    original: `${strapiURL}${photo?.formats?.large?.url || photo?.formats?.medium?.url || photo?.formats?.thumbnail?.url || ''}`,
                    thumbnail: `${strapiURL}${photo?.formats?.thumbnail?.url || ''}`,
                  }))}
                  showFullscreenButton={true}
                  showPlayButton={true}
                  autoPlay={true}
                  slideInterval={6000}
                  slideDuration={450}
                  showBullets={true}
                  showThumbnails={true}
                  showIndex={true}
                  infinite={true}
                  showNav={true}
                  thumbnailPosition="top"
                  useBrowserFullscreen={true}
                  useWindowKeyDown={true}
                />
              )
            }

            <hr></hr>
            <p>Opublikowane {item?.Date}</p>
            <p className="lead">{item?.Title}</p>
            
            <p className="text-white-50">
              <ReactMarkdown>
                {item?.Body}
              </ReactMarkdown>
            </p>


          </div>
          <div className="col-lg-4">
            <div className="d-flex align-item-center title mb-3 mt-5">
                <h5 className="m-0 font-weight-normal">Inne wpisy
                </h5>
            </div>

            <div className="row">
              <div className="col-lg-12">
                
                <BlogListsBlog bloglistsblog={itemBlogsList} />

                
              </div>
            </div>


          </div>
          



        </div>
      </div>
    </section>
    
  )
}
