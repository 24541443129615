import React, { useEffect, useState, useContext, useMemo} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { AppContext } from "../App";


import CollectionItemParametry from '../components/CollectionItemParametry'
import CollectionItemPhotos from '../components/CollectionItemPhotos'
import CollectionItemLinks from '../components/CollectionItemLinks'
import ShareButton from '../components/ShareButton';

export default function BlogItem() {
  const strapiURL = useContext(AppContext)
  const token = process.env.REACT_APP_API_KEY
  const headers = useMemo(() => ({
    headers: { Authorization: `Bearer ${token}` }
  }), [token]);

  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [itemLinks, setItemLinks] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${strapiURL}/api/collections/${id}`, { ...headers,
          params: {
            populate: '*', 
          }
        });
        // console.log(response.data.data);
        setItem(response.data.data);
        try {
            if(response && response.data && response.data.data && response.data.data.manufacturer && response.data.data.manufacturer && response.data.data.manufacturer.id) {
                const manufacturerId = response.data.data.manufacturer.id;
                const responseLinks = await axios.get(`${strapiURL}/api/links`, {
                    ...headers,   
                    params: {
                        'sort[0]': 'Name:ASC',
                        populate: '*', 
                        'filters[manufacturers][id][$in]': manufacturerId
                    }
                });
                // console.log(responseLinks.data);
                setItemLinks(responseLinks.data);
                // console.log(itemLinks.data.length)
            } else {
                console.error("Response data structure is not as expected");
            }
        } catch(err) {
            console.error(err.message);
        }
        


      } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
      }
    };
      fetchData();
    }, [headers, id, strapiURL, itemLinks?.data?.length ]);

  
  return (
    <section className="py-5 store-page">
      <div className="container">   
 
        <div className="row">
          <div className="col-lg-3">
            <div className="position-sticky">

                {/* <div className="gradient-1 p-4 mb-1">
                  <img className="img-fluid" src="img/g-logo.png" alt=""/>
                </div> */}
                {item ? (
                  <h3 className="mt-4 mb-3"> {item.Name}
                  </h3>
                ) : (
                  <p>Loading...</p>
                )}
                  
                  
                <p className="font-weight-normal mb-4  bg-primary d-inline-block text-center px-2 py-1 text-white">
                {(item && item.manufacturer && item.manufacturer && item.manufacturer.Name) || '-'}
                </p>
                {/* <div className="d-flex mb-4">
                
                  <div className="btn btn-danger col-8"><i className="feather-heart mr-1"></i> Favorite</div>
                  <div className="btn btn-warning col-4 px-2"><i className="feather-download-cloud mr-1"></i> Get</div>
                </div> */}
                <div className="bg-dark p-3 sidebar-widget mb-4">
                  <div className="nav nav-pills flex-column lavalamp">
                      {
                        item?.Photos?.length > 0 && (
                          <a className="nav-link" href="#Photos"><i className="feather-home mr-1"></i> Zdjęcia</a>
                        )
                      }
                      {
                        item?.Opis?.length > 0 || item?.Procesor.length > 0 && (
                          <a className="nav-link" href="#Specyfikacja"><i className="feather-edit mr-1"></i> Specyfikacja</a>
                        )
                      }
                      {
                        itemLinks?.length > 0 && (
                          <a className="nav-link" href="#Links"><i className="feather-award mr-1"></i> Adresy stron</a>
                        )
                      }
                      {/* <a className="nav-link" href="#video"><i className="feather-video mr-1"></i>Video</a>
                      <a className="nav-link" href="#follow"><i className="feather-share-2 mr-1"></i> Follow Us</a>
                      <a className="nav-link" href="#offers"><i className="feather-award mr-1"></i> Offers</a>
                      <a className="nav-link" href="#ratings"><i className="feather-star mr-1"></i> Ratings</a>
                      <a className="nav-link" href="#specifications"><i className="feather-edit mr-1"></i> Specifications</a> */}
                  </div>
                </div>
                <ShareButton />
            </div>
          </div>
          <div className="col-lg-9">
            <div className="row">
                <div className="col-lg-12">

                  {
                    item?.Photos?.length > 0 && (
                      <>
                        <div className="pt-4" id="Photos"></div>
                        <h5 className="mb-3 mt-4"> Zdjęcia</h5>
                        <CollectionItemPhotos d={item} />
                      </>
                    )
                  }

                  {
                    item?.Opis?.length > 0 || item?.Procesor.length > 0 && (
                      <>
                        <div className="pt-4" id="Specyfikacja"></div>
                        <h5 className="mb-3 mt-4"> Specyfikacja</h5>
                        <CollectionItemParametry d={item} />
                      </>
                    )
                  }

                  {
                    
                    itemLinks?.data?.length > 0 && (
                      <>
                        <div className="pt-4" id="Links"></div>
                        <h5 className="mb-3 mt-4"> Adresy stron</h5>
                        <CollectionItemLinks linksArray={itemLinks} />
                      </>
                    )
                  }

   
               
                </div>
            </div>
          </div>
        
        
        
        </div>
    
      </div>
    </section>
    
  )
}
