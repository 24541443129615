import React, { useContext } from "react";
import { AppContext } from "../App";


export default function BlogListsBlog({ bloglistsblog = [] }) {

    const strapiURL = useContext(AppContext)
    if (!bloglistsblog ) {
        return null; 
    }

    

return (
  <>
    {bloglistsblog.map((item, index) => {
      let imageUrl;
      if (
        item && 
        item.Photos && 
        item.Photos.length > 0
      ) {
        
        const randomIndex = Math.floor(Math.random() * item.Photos.length);
        const photo = item.Photos[randomIndex];
        if (
          photo.formats && 
          photo.formats.thumbnail
        ) {
          imageUrl = photo.formats.thumbnail.url;
          imageUrl = `${strapiURL}${imageUrl}`;
        }
      }

      return (
        <div className="osahan-card">
          <a href={`/blog/${item?.documentId}`}>
            <img className="img-fluid" src={imageUrl} alt=""/>
            <div className="osahan-card-body mt-3">
                <h6 className="text-white mb-1">{item?.Title} </h6>
                <p className="m-0 text-white-50"><i className="feather-user text-white"></i> {item?.Date}</p>
            </div>
          </a>
        </div>
        
      );
    })}
  </>
);

}
