import React, { Component, useContext } from "react";
import { AppContext } from "../App";

export default function LinksRow({ linksArray }) {
  const strapiURL = useContext(AppContext)
  
  if (!linksArray || !linksArray.data ) {
      return null; 
  }

  return (
    <ul className="list-group">
      {linksArray.data.map((item, index) => (
        <li className="list-group-item" key={index}>
          <div className="row">
              <div className="col-lg-6">
                  {item?.Name}
              </div>
              <div className="col-lg-6">
                  {item?.Description}
              </div>
              <div className="col-lg-12">
                  <a href={`https://${item?.Link}`} target="_blank" rel="noopener noreferrer">
                    {item?.Link}
                  </a>
              </div>
              <div className="col-lg-12">
              {item && item?.manufacturers?.map((manufacturer) => (
                  <span key={manufacturer.id} className="badge badge-info">{manufacturer.Name}</span>
                ))}
              </div>
              
          </div>
        </li>
      ))}
    </ul>
  );
}
