import React, { useContext } from "react";
import { AppContext } from "../App";

export default function HomepageBlog({ d }) {
  
  const strapiURL = useContext(AppContext);

  let imageUrl;
  const imageUrlPath = d?.Photos?.[0]?.formats?.small?.url;
  if (imageUrlPath) {
    imageUrl = `${strapiURL}${imageUrlPath}`;
  }

  // Ensure `d` is defined before rendering
  if (!d) {
    return null;
  }

  return (
    <div className="card mb-3">
      <a href={`/blog/${d.documentId}`} className="list-group-item list-group-item-action1">
        <div className="card text-center">
          {imageUrl && <img className="img-fluid" src={imageUrl} alt={d.Title} />}
          <div className="card-body">
            <h5 className="card-title text-white">{d.Title}</h5>
            <p className="card-text">
              {d.Body.length > 100 ? d.Body.slice(0, 100) + ' ...' : d.Body}
            </p>
            <p className="card-text"><small className="text-muted">{d.Date}</small></p>
          </div>
        </div>
      </a>
    </div>
  );
}



