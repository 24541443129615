import React, { useEffect, useState, useContext, useMemo} from 'react'
// import { Link } from 'react-router-dom'
import axios from 'axios'
import { AppContext } from "../App";
import CollectionBox from '../components/CollectionBox'


export default function Homepage() {
  const strapiURL = useContext(AppContext)
  const token = process.env.REACT_APP_API_KEY
  const headers = useMemo(() => ({
      headers: { Authorization: `Bearer ${token}` }
  }), [token]);


  const [data,setData] = useState([])
  
  const [filterKind, setfilterKind] = useState([])
  const [selectedKinds, setSelectedKinds] = useState([]);
  const kindFilters = {};
  selectedKinds.forEach((kindId, index) => {
    kindFilters[`filters[kind][id][$in][${index}]`] = kindId;
  });

  const [filterManufacturer, setfilterManufacturer] = useState([])
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);
  const manufacturerFilters = {};
  selectedManufacturers.forEach((manufacturerId, index) => {
    manufacturerFilters[`filters[manufacturer][id][$in][${index}]`] = manufacturerId;
  });


  const [page, setPage] = useState(1); // Numer aktualnej strony
  const pageSize = 24; // Liczba wyników na stronę
  const [totalPages, setTotalPages] = useState(0);
  const [totalIteams, setTotalIteams] = useState(0);
  const [sortOrder, setSortOrder] = useState('ASC');
  

  
  useEffect(() => {
    const fetchData = async () => {
      try {

        const responseFilter1 = await axios.get(`${strapiURL}/api/kinds`, { ...headers,
          params: {
            'sort[0]': 'Name:ASC', // Sortowanie malejąco po dacie
            // 'pagination[page]': page,
            // 'pagination[pageSize]': pageSize,
            // 'populate': '*', 
          }
        });
        // console.log(responseFilter1.data)
        setfilterKind(responseFilter1.data.data);

        const responseFilter2 = await axios.get(`${strapiURL}/api/manufacturers`, { ...headers,
          params: {
            'sort[0]': 'Name:ASC', // Sortowanie malejąco po dacie
            // 'pagination[page]': page,
            // 'pagination[pageSize]': pageSize,
            // 'populate': '*', 
          }
        });
        // console.log(responseFilter2.data)
        setfilterManufacturer(responseFilter2.data.data);

        const response = await axios.get(`${strapiURL}/api/collections`, { ...headers,
          params: {
            'sort[0]': `Name:${sortOrder}`, // Sortowanie malejąco po dacie
            'pagination[page]': page,
            'pagination[pageSize]': pageSize,
            'populate': '*', 
            // 'filters[kind][id]': selectedKinds.join(','),
            ...kindFilters,
            ...manufacturerFilters,
          }
        });
        // console.log(selectedKinds.join(','))
        setTotalPages(response.data.meta.pagination.pageCount);
        setTotalIteams(response.data.meta.pagination.total);
        // console.log(response.data)
        setData(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
  
      fetchData();
    }, [page, selectedKinds, selectedManufacturers, sortOrder, headers, strapiURL ]);

    const handleNextPage = () => {
      // Przejdź do następnej strony
      if (page < totalPages) {
        setPage(page + 1);
      }
    };
  
    const handlePreviousPage = () => {
      // Przejdź do poprzedniej strony
      if (page > 1) {
        setPage(page - 1);
      }
    };
  
    const pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      pageButtons.push(
        <li key={i} className={`page-item ${i === page ? "active" : ""}`}>
          <button className="page-link" onClick={() => setPage(i)}>
            {i}
          </button>
        </li>
      );
    }

  return (
    <section className="py-5 store-page">
      <div className="container"> 
      {/* pasek na gorze  */}
        <div className="d-flex align-item-center title mb-3">
          <h5 className="m-0 font-weight-normal">Znalezionych - <span className="text-white-50">{totalIteams}</span>
          </h5>
          <div className="ml-auto d-flex align-items-center">
              Sort By: 
              <div className="dropdown ml-2">
                <button className="btn btn-sm btn-outline-light dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Alfabetycznie A-Z
                </button>
                <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
                  <button className="dropdown-item" type="button" onClick={() => setSortOrder('ASC')}>Alfabetycznie A-Z</button>
                  <button className="dropdown-item" type="button" onClick={() => setSortOrder('DESC')}>Alfabetycznie Z-A</button>
                </div>

              </div>
          </div>
        </div>
      {/* menu po prawej */}
      <div className="row">
        <div className="col-lg-3">
          <div className="filters rounded mb-4">
              <div className="filters-header border-bottom pb-4">
                <h6 className="m-0">Filter By</h6>
              </div>

              <div className="filters-body">
                <div id="accordion">
                           <div className="filters-card border-bottom py-4">
                              <div className="filters-card-header" id="headingOne">
                                 <h6 className="mb-0">
                                    RODZAJ 
                                 </h6>
                              </div>
                              <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                 <div className="filters-card-body card-shop-filters">
                                    {
                                      filterKind.map((d, i) => {
                                        // console.log(d);
                                        return (
                                          <div className="custom-control custom-checkbox" key={i}>
                                            {/* <input type="checkbox" className="custom-control-input" id={`cb${i}`} /> */}
                                            <input
                                              type="checkbox"
                                              className="custom-control-input"
                                              id={`cb${i}`}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  setSelectedKinds((currentSelectedKinds) => [...currentSelectedKinds, d.id]);
                                                } else {
                                                  setSelectedKinds((currentSelectedKinds) => currentSelectedKinds.filter(id => id !== d.id));
                                                }
                                              }}
                                            />

                                            <label className="custom-control-label" htmlFor={`cb${i}`}>{d.Name}</label>
                                          </div>
                                        );
                                      })
                                    }

                                    
                                    {/* <div className="mt-2"><a href="#" className="link">See all</a></div> */}
                                 </div>
                              </div>
                           </div>
                           <div className="filters-card border-bottom py-4">
                              <div className="filters-card-header" id="headingTwo">
                                 <h6 className="mb-0">
  
                                    PRODUCENCI 
                                   
                                 </h6>
                              </div>
                              <div id="collapsetwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
                                 <div className="filters-card-body card-shop-filters">
                                    {
                                      filterManufacturer.map((da, ia) => {
                                        // console.log(da);
                                        return (
                                          <div className="custom-control custom-checkbox" key={ia}>
                                            {/* <input type="checkbox" className="custom-control-input" id={`cba${ia}`} /> */}
                                            <input 
                                              type="checkbox" 
                                              className="custom-control-input" 
                                              id={`cba${ia}`} 
                                              onChange={(e) => {
                                                const manufacturerId = da.id;
                                                if (e.target.checked) {
                                                  setSelectedManufacturers(currentSelectedManufacturers => [...currentSelectedManufacturers, manufacturerId]);
                                                } else {
                                                  setSelectedManufacturers(currentSelectedManufacturers => currentSelectedManufacturers.filter(id => id !== manufacturerId));
                                                }
                                              }} 
                                            />



                                            <label className="custom-control-label" htmlFor={`cba${ia}`}>{da.Name}</label>
                                          </div>
                                        );
                                      })
                                    }



                                    
                                    {/* <div className="mt-2"><a href="#" className="link">See all</a></div> */}
                                 </div>
                              </div>
                           </div>
   
              </div>
            </div>



          </div>
        </div>

        <div className="col-lg-9">
          <div className="row">
          {data.map((d,i) => {
                // console.log(d)
                
                return <CollectionBox key={d.id} d={d} />
                // <li key={d.id}> {d.id} {d.Name} <Link to={`manufacturer/${d.id}`}>{d.Name}</Link> 
                // </li>
              })
            }
          </div>
          <div className="card-body">
              <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
                      <button className="page-link" onClick={handlePreviousPage}>Poprzednia</button>
                    </li>
                    {pageButtons}
                    <li className={`page-item ${page === totalPages ? "disabled" : ""}`}>
                      <button className="page-link" onClick={handleNextPage}>Następna</button>
                    </li>
                  </ul>
                </nav>
            </div>
        </div>
      </div>

      </div>
    </section>
    
  )
}
